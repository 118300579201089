import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addMemberInsuranceService,
  getMemberInsuranceService,
  getCancellationInsuranceService,
  deleteMemberInsuranceService,
  cancellationMemberInsuranceService,
  getMemberPDFPolicyService,
  getMemberExcelPolicyService,
  destroyPolicyInsuranceByIdService,
  getMemberPoliciesByCancellationDateService,
  getAllMemberPDFPolicyService,
  getAllMemberExcelPolicyService
} from './../../Services/memberInsuranceService'
import { showNotification } from './notification';
import { searchMemberById } from './member'

export const reducerName = 'memberInsurance';

const initialState = {
  request: false,
  error: null,
  data: null,

  getData: null,
  getError: null,
  getRequest: null,

  getCancellationInsuranceData: null,
  getCancellationInsuranceError: null,
  getCancellationInsuranceRequest: null,

  getMemberPDFPolicyRequest: null,
  getMemberPDFPolicyError: null,
  getMemberPDFPolicyData: null,

  memberPoliciesByCancellationDateError: null,
  memberPoliciesByCancellationDateRequest: false,
  memberPoliciesByCancellationDateData: [],

  filteredCancellationDataTable: null
};

export const addMemberInsurance = createAsyncThunk(
  'memberInsurance/add',
  async (memberInsuranceData, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await addMemberInsuranceService(memberInsuranceData);

      dispatch(showNotification({
        message: 'Insurance created successfully',
        type: 'SUCCESS'
      }));

      dispatch(getMemberInsurance(memberInsuranceData.memberId));
      dispatch(searchMemberById(memberInsuranceData.memberId));

      return {
        data: response
      };
    } catch (error) {
      
      dispatch(showNotification({
        message: error.message[Object.keys(error.message)[0]][0],
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const deleteMemberInsurance = createAsyncThunk(
  'memberInsurance/delete',
  async ({memberInsuranceId, memberId}, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await deleteMemberInsuranceService(memberInsuranceId);

      dispatch(getMemberInsurance(memberId));

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const destroyPolicyInsuranceById = createAsyncThunk(
  'memberInsurance/destroy',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await destroyPolicyInsuranceByIdService(data.policyId);

      data.memberId && dispatch(getCancellationInsurance(data.memberId));

      dispatch(showNotification({
        message: 'Insurance deleted successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message[Object.keys(error.message)[0]][0],
        type: 'ERROR'
      }));
      
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const cancellationMemberInsurance = createAsyncThunk(
  'memberInsurance/cancellation',
  async (memberInsuranceData, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await cancellationMemberInsuranceService(memberInsuranceData);

      dispatch(getMemberInsurance(memberInsuranceData.memberId));
      
      dispatch(showNotification({
        message: 'Cancellation successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {
      
      dispatch(showNotification({
        message: error.message[Object.keys(error.message)[0]][0],
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getMemberInsurance = createAsyncThunk(
  'memberInsurance/get',
  async (memberId, { rejectWithValue }) => {
    try {
      
      const response = await getMemberInsuranceService(memberId);

      return {
        data: response?.memberPolicies
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getCancellationInsurance = createAsyncThunk(
  'memberCancellationInsurance/get',
  async (memberId, { rejectWithValue }) => {
    try {
      
      const response = await getCancellationInsuranceService(memberId);

      return {
        data: response?.memberPoliciesCancellation
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getMemberPDFPolicy = createAsyncThunk(
  'memberPDFPolicy/get',
  async (data, { rejectWithValue }) => {
    try {
      
      const response = await getMemberPDFPolicyService(data);

      return {
        data: response?.file
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getMemberExcelPolicy = createAsyncThunk(
  'memberExcelPolicy/get',
  async (memberId, { rejectWithValue }) => {
    try {
      
      const response = await getMemberExcelPolicyService(memberId);

      return {
        data: response?.file
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getMemberPoliciesByCancellationDate = createAsyncThunk(
  'memberInsurance/getMemberPoliciesByCancellationDate',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await getMemberPoliciesByCancellationDateService(data);

      return {
        data: response.member_policies
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));
      
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

const setFilteredCancellationDataTableReducer = (state, action) => {
  return {
    ...state,
    filteredCancellationDataTable: action.payload.data
  }
};

const setFilteredCancellationDataTableAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const clearFilteredCancellationDataTableReducer = (state) => {
  return {
    ...state,
    filteredCancellationDataTable: null
  }
};

export const getAllMemberPDFPolicy = createAsyncThunk(
  'memberInsurance/getAllMemberPDFPolicy',
  async (parameters, { rejectWithValue }) => {
    try {
      
      const response = await getAllMemberPDFPolicyService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getAllMemberExcelPolicy = createAsyncThunk(
  'memberInsurance/getAllMemberExcelPolicy',
  async (parameters, { rejectWithValue }) => {
    try {
      
      const response = await getAllMemberExcelPolicyService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

const setDateFilterInsuranceCancellationTableReducer = (state, action) => {
  return {
    ...state,
    dateFilterCancellationTable: action.payload.data
  }
};

const setDateFilterInsuranceCancellationTableAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const clearNewPolicyFormReducer = (state) => {
  return {
    ...state,
    data: null
  }
}

export const memberInsuranceReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    clearNewPolicyForm: clearNewPolicyFormReducer,
    setFilteredCancellationDataTable: {
      reducer: setFilteredCancellationDataTableReducer,
      prepare: setFilteredCancellationDataTableAction
    },
    setDateFilterInsuranceCancellationTable: {
      reducer: setDateFilterInsuranceCancellationTableReducer,
      prepare: setDateFilterInsuranceCancellationTableAction
    },
    clearFilteredCancellationDataTable: clearFilteredCancellationDataTableReducer
  },
  extraReducers: (builder) => {

    builder.addCase(addMemberInsurance.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    })

    builder.addCase(addMemberInsurance.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(addMemberInsurance.pending, (state) => {
      state.request = true;
      state.error = null;
      state.data = null;
    })

    builder.addCase(getMemberInsurance.fulfilled, (state, action) => {
      state.getData = action.payload?.data;
      state.getRequest = false;
    })

    builder.addCase(getMemberInsurance.rejected, (state, action) => {
      state.getError = action.payload.error;
      state.getRequest = false;
      state.getData = null;
    })

    builder.addCase(getMemberInsurance.pending, (state) => {
      state.getRequest = true;
      state.getError = null;
    })

    builder.addCase(getCancellationInsurance.fulfilled, (state, action) => {
      state.getCancellationInsuranceData = action.payload?.data;
      state.getCancellationInsuranceRequest = false;
    })

    builder.addCase(getCancellationInsurance.rejected, (state, action) => {
      state.getCancellationInsuranceError = action.payload.error;
      state.getCancellationInsuranceRequest = false;
      state.getCancellationInsuranceData = null;
    })

    builder.addCase(getCancellationInsurance.pending, (state) => {
      state.getCancellationInsuranceRequest = true;
      state.getCancellationInsuranceError = null;
    })

    builder.addCase(deleteMemberInsurance.fulfilled, (state, action) => {
      state.deleteMemberInsuranceData = action.payload?.data;
      state.deleteMemberInsuranceRequest = false;
    })

    builder.addCase(deleteMemberInsurance.rejected, (state, action) => {
      state.deleteMemberInsuranceError = action.payload.error;
      state.deleteMemberInsuranceRequest = false;
      state.deleteMemberInsuranceData = null;
    })

    builder.addCase(deleteMemberInsurance.pending, (state) => {
      state.deleteMemberInsuranceRequest = true;
      state.deleteMemberInsuranceError = null;
      state.deleteMemberInsuranceData = null;
    })


    builder.addCase(cancellationMemberInsurance.fulfilled, (state, action) => {
      state.cancellationMemberInsuranceData = action.payload?.data;
      state.cancellationMemberInsuranceRequest = false;
    })

    builder.addCase(cancellationMemberInsurance.rejected, (state, action) => {
      state.cancellationMemberInsuranceError = action.payload.error;
      state.cancellationMemberInsuranceRequest = false;
      state.cancellationMemberInsuranceData = null;
    })

    builder.addCase(cancellationMemberInsurance.pending, (state) => {
      state.cancellationMemberInsuranceRequest = true;
      state.cancellationMemberInsuranceError = null;
      state.cancellationMemberInsuranceData = null;
    })

    builder.addCase(getMemberPDFPolicy.fulfilled, (state, action) => {
      state.getMemberPDFPolicyData = action.payload?.data;
      state.getMemberPDFPolicyRequest = false;
    })

    builder.addCase(getMemberPDFPolicy.rejected, (state, action) => {
      state.getMemberPDFPolicyError = action.payload.error;
      state.getMemberPDFPolicyRequest = false;
      state.getMemberPDFPolicyData = null;
    })

    builder.addCase(getMemberPDFPolicy.pending, (state) => {
      state.getMemberPDFPolicyRequest = true;
      state.getMemberPDFPolicyError = null;
      state.getMemberPDFPolicyData = null;
    })

    builder.addCase(destroyPolicyInsuranceById.fulfilled, (state, action) => {
      state.destroyPolicyData = action.payload?.data;
      state.destroyPolicyRequest = false;
    })

    builder.addCase(destroyPolicyInsuranceById.rejected, (state, action) => {
      state.destroyPolicyError = action.payload.error;
      state.destroyPolicyRequest = false;
      state.destroyPolicyData = null;
    })

    builder.addCase(destroyPolicyInsuranceById.pending, (state) => {
      state.destroyPolicyRequest = true;
      state.destroyPolicyError = null;
      state.destroyPolicyData = null;
    })


    builder.addCase(getMemberPoliciesByCancellationDate.fulfilled, (state, action) => {
      state.memberPoliciesByCancellationDateData = action.payload?.data;
      state.memberPoliciesByCancellationDateRequest = false;
    })

    builder.addCase(getMemberPoliciesByCancellationDate.rejected, (state, action) => {
      state.memberPoliciesByCancellationDateError = action.payload.error;
      state.memberPoliciesByCancellationDateRequest = false;
      state.memberPoliciesByCancellationDateData = null;
    })

    builder.addCase(getMemberPoliciesByCancellationDate.pending, (state) => {
      state.memberPoliciesByCancellationDateRequest = true;
      state.memberPoliciesByCancellationDateError = null;
      state.memberPoliciesByCancellationDateData = null;
    })

    builder.addCase(getAllMemberPDFPolicy.fulfilled, (state, action) => {
      state.getAllMemberPDFPolicyData = action.payload?.data;
      state.getAllMemberPDFPolicyRequest = false;
    })

    builder.addCase(getAllMemberPDFPolicy.rejected, (state, action) => {
      state.getAllMemberPDFPolicyError = action.payload.error;
      state.getAllMemberPDFPolicyRequest = false;
      state.getAllMemberPDFPolicyData = null;
    })

    builder.addCase(getAllMemberPDFPolicy.pending, (state) => {
      state.getAllMemberPDFPolicyRequest = true;
      state.getAllMemberPDFPolicyError = null;
      state.getAllMemberPDFPolicyData = null;
    })

    builder.addCase(getAllMemberExcelPolicy.fulfilled, (state, action) => {
      state.getAllMemberExcelPolicyData = action.payload?.data;
      state.getAllMemberExcelPolicyRequest = false;
    })

    builder.addCase(getAllMemberExcelPolicy.rejected, (state, action) => {
      state.getAllMemberExcelPolicyError = action.payload.error;
      state.getAllMemberExcelPolicyRequest = false;
      state.getAllMemberExcelPolicyData = null;
    })

    builder.addCase(getAllMemberExcelPolicy.pending, (state) => {
      state.getAllMemberExcelPolicyRequest = true;
      state.getAllMemberExcelPolicyError = null;
      state.getAllMemberExcelPolicyData = null;
    })
  }
});
export const { setFilteredCancellationDataTable, clearFilteredCancellationDataTable, setDateFilterInsuranceCancellationTable, clearNewPolicyForm } = memberInsuranceReducer.actions

export default memberInsuranceReducer.reducer;
