import React, { useEffect, useRef, useState } from 'react'
import GenerateForm from './../../../../Components/Forms/generate_form'
import { useDispatch, useSelector } from 'react-redux';
import { reducerName as memberReducerName } from './../../../../Store/Reducers/member';
import { getPolicyByCompany, reducerName as policyReducerName } from './../../../../Store/Reducers/policy';
import { addMemberInsurance, reducerName as memberInsuranceReducerName, clearNewPolicyForm } from './../../../../Store/Reducers/memberInsurance';
import { newPolicyFormFields } from './constants'
import Notification from './../../../../Components/notification';

const formName = 'newPolicyForm';

const NewPolicyForm = ({showInsuranceTable}) => {
  const [customValues, setCustomValues] = useState({});
  const dispatch = useDispatch();
  const insuranceCompanyControl = useRef();
  const insuranceCompanyPolicyControl = useRef();
  const insuranceReasonControl = useRef();
  const managementData = useSelector((state)=> state[memberReducerName].managementData);
  const memberInsuranceData = useSelector((state)=> state[memberInsuranceReducerName].data);
  const addMemberInsuranceRequest = useSelector((state)=> state[memberInsuranceReducerName].request);
  const policies = useSelector((state)=> state[policyReducerName].data);
  const [selectedPolicy, setSelectedPolicy] = useState();

  useEffect(()=>{
    if(!!memberInsuranceData){
      const form = document.getElementById(formName);
      form?.reset();
      insuranceCompanyControl?.current?.clearValue();
      insuranceCompanyPolicyControl?.current?.clearValue();
      insuranceReasonControl?.current?.clearValue();
      setCustomValues({join_date: null});
      setTimeout(() => {
        dispatch(clearNewPolicyForm());
        showInsuranceTable();
      }, 1500);
    }
  }, [memberInsuranceData]);

  const handleInputChange = (event) => {
    if(event.target.name === 'insurance_company_id') {
      !!event.target.value && dispatch(getPolicyByCompany(event.target.value));
    }
    if(event.target.name === 'policy_id') {
      setSelectedPolicy(policies.find((e)=> e.id === event.target.value))
    }
	};

  const handleFormSubmit = (e) => {
			
		e.preventDefault();

		if(document.querySelectorAll(`#${formName} input.is-invalid`).length) {
			document.querySelector(`#${formName} input.is-invalid`).focus();
			return;
		}
    setCustomValues({})
		
		const elements = e.target.elements;
		const memberData ={};
		const fields = [];

		newPolicyFormFields.forEach((formMember)=>formMember.forms?.forEach((field)=>fields.push(field)))
		
		for (let i = 0; i < elements.length; i++) {

				const { name, value } = elements.item(i);

        if(!name.length) {
          continue;
        }

				const fieldConfiguration = fields.find((field)=> field?.name === name);

				if(fieldConfiguration?.type === "date") {
					const dateValue = value.split("/");
					if(dateValue.length >= 2) {
						memberData[name] = `${dateValue[2]}-${dateValue[0]}-${dateValue[1]}`;
					}
				} else {

					if(fieldConfiguration?.required && !value) {
						elements.item(i).parentElement?.childNodes?.[2]?.childNodes?.[0]?.childNodes?.[1]?.childNodes?.[0]?.focus();
						return;
					}
					
					memberData[name] = value;
				}
		}

		dispatch(addMemberInsurance({
			memberId: managementData.member.id, data: memberData
		}));

	}

	return (<>
    <form id={formName} onSubmit={handleFormSubmit}>
      <div className="row">
        {
          newPolicyFormFields.map((section, i) =>
            <>
            <label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
            {
              section.forms.map((field)=>({
                ...field,
                disabled: (!managementData || !Object.keys(managementData).length) || field.disabled,
                ...(field.name === 'insurance_company_id' && { refVar: insuranceCompanyControl }),
                ...(field.name === 'reason_id' && { refVar: insuranceReasonControl }),
                ...(field.name === 'policy_id' && { refVar: insuranceCompanyPolicyControl, defaultValue: selectedPolicy?.amount }),
                defaultValue: typeof field?.getDefaultValue === 'function' ?
                field.getDefaultValue(managementData) :
                (managementData?.[field.name] || customValues[field.name]),
                ...(field.name === 'cost' && { defaultValue: selectedPolicy?.amount }),
              })).map((form, i) => <GenerateForm
                key={i}
                {...form}
                onChange={handleInputChange}
                dataInputDates={()=>{}}
              /> )
            }
            </>					
          )
        }
        <Notification />
        <div className='col-12' />
        <div className='col-2 show-on-mobile' />
        <div className='col-8 col-lg-2 mt-3'>
          <button type='button' disabled={addMemberInsuranceRequest} onClick={showInsuranceTable} className='btn-light-grey btn btn-default btn-primary'>Back</button>
        </div>
        <div className='col-2 show-on-mobile' />
        <div className='col-2 show-on-mobile' />
        <div className='col-8 col-lg-2 mb-3 mt-3'>
          <button type='submit' disabled={addMemberInsuranceRequest} className='btn-blue btn btn-default btn-primary'>Add</button>
        </div>
      </div>
    </form>
  </>)
}

export default NewPolicyForm