import React, { useEffect,useRef, useState } from 'react'
import GenerateForm from '../../../../Components/Forms/generate_form'
import { useDispatch, useSelector } from 'react-redux';
import { reducerName as memberReducerName } from '../../../../Store/Reducers/member';
import Notification from '../../../../Components/notification';
import { reducerName as memberNoteReducerName, addMemberNote, closeEditForm, editMemberNote } from '../../../../Store/Reducers/memberNote';
import { newNoteFormFields } from './constants'

const formName = 'newNoteForm';

const NewNoteForm = ({showNoteTable}) => {
  const [customValues, setCustomValues] = useState({});
  const dispatch = useDispatch();
	const noteTypeSearchControl = useRef();
  const managementData = useSelector((state)=> state[memberReducerName].managementData);
  const memberNoteData = useSelector((state)=> state[memberNoteReducerName].data);
  const addMemberRequest = useSelector((state)=> state[memberNoteReducerName].request);
  const editMemberNoteData = useSelector((state)=> state[memberNoteReducerName].getMemberNoteData);

  const editMemberNoteRequest = useSelector((state)=> state[memberNoteReducerName].editMemberNoteRequest);
  const editMemberNoteDataResult = useSelector((state)=> state[memberNoteReducerName].editMemberNoteData);

  useEffect(()=>{
    if(!!memberNoteData){
      const form = document.getElementById(formName);
      form?.reset();
      noteTypeSearchControl.current?.clearValue();
      setCustomValues({updated_at: null, note: ''});
      setTimeout(()=>{document.querySelectorAll(`#${formName} .text-danger`).forEach((text)=>{
        text.innerHTML = '';
      })}, 100);
      setTimeout(() => {
        handleCloseEditNoteForm();
      }, 1500);
    }
  }, [memberNoteData, setCustomValues]);

  useEffect(()=>{
    if(!!editMemberNoteDataResult){
      dispatch(closeEditForm());
      const form = document.getElementById(formName);
      form?.reset();
      noteTypeSearchControl.current?.clearValue();
      setCustomValues({updated_at: null, note: ''});
      setTimeout(()=>{document.querySelectorAll(`#${formName} .text-danger`).forEach((text)=>{
        text.innerHTML = '';
      })}, 100);
      setTimeout(() => {
        handleCloseEditNoteForm();
      }, 1500);
    }
  }, [editMemberNoteDataResult, setCustomValues]);

  const handleFormSubmit = (e) => {
			
		e.preventDefault();

		if(document.querySelectorAll(`#${formName} input.is-invalid`).length) {
			document.querySelector(`#${formName} input.is-invalid`).focus();
			return;
		}
		
		const elements = e.target.elements;
		const formData ={};
		const fields = [];

		newNoteFormFields.forEach((formMember)=>formMember.forms?.forEach((field)=>fields.push(field)))
		
		for (let i = 0; i < elements.length; i++) {

				const { name, value } = elements.item(i);
        
        if(!name?.length) {
          continue;
        }

				const fieldConfiguration = fields.find((field)=> field?.name === name);

				if(fieldConfiguration?.type === "date") {
					const dateValue = value.split("/");
					if(dateValue.length >= 2) {
						formData[name] = `${dateValue[2]}-${dateValue[0]}-${dateValue[1]}`;
					}
				} else {

					if(fieldConfiguration?.required && !value) {
						elements.item(i).parentElement?.childNodes?.[2]?.childNodes?.[0]?.childNodes?.[1]?.childNodes?.[0]?.focus();
						return;
					}
					
					formData[name] = value;
				}
		}

    if(editMemberNoteData) {
      dispatch(editMemberNote({
        id: editMemberNoteData.id,
        data: formData
      }));
      return;
    }

		dispatch(addMemberNote({
			memberId: managementData.member.id, data: formData
		}));
	}

  const handleCloseEditNoteForm = () => {
    dispatch(closeEditForm());
    showNoteTable();
  }

  return (<>
    <form id={formName} onSubmit={handleFormSubmit}>
    <div className='row'>
        {
          newNoteFormFields.map((section, i) => section.forms.map((field)=>({
                ...field,
                defaultValue: typeof field?.getDefaultValue === 'function' ?
                  field.getDefaultValue(editMemberNoteData) :
                  (editMemberNoteData?.[field.name] || customValues[field.name]),
                ...(field.name === 'note_type_id' && { refVar: noteTypeSearchControl })
              })).map((form, i) => <GenerateForm
                key={i}
                dataInputDates={()=>{}}
                onChange={()=>{}}
                {...form}
              /> )
          )
        }
        <div className='col-12' />
        <Notification />
        <div className='col-2 show-on-mobile' />
        <div className='col-8 col-lg-2 mt-3'>
          <button type='button' disabled={addMemberRequest || editMemberNoteRequest} onClick={handleCloseEditNoteForm} className='btn-light-grey btn btn-default btn-primary'>Back</button>
        </div>
        <div className='col-2 show-on-mobile' />
        <div className='col-2 show-on-mobile' />
        <div className='col-8 col-lg-2 mb-3 mt-3'>
          <button type='submit' disabled={addMemberRequest || editMemberNoteRequest} className='btn-blue btn btn-default btn-primary'>{ editMemberNoteData ? 'Update': 'Add' }</button>
        </div>
      </div>
    </form>
  </>)
}

export default NewNoteForm