import React, { useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';


function PieChartComponent(props) {
  const [selected, setSelected] = useState();
  const [hovered, setHovered] = useState(undefined);

  const data = props.data?.map((entry, i) => {
    if (hovered === i) {
      return {
        ...entry,
        color: 'grey',
      };
    }
    return entry;
  }) || [];

  const lineWidth = 40;

  return (<>
    <PieChart
      style={{
        fontFamily:
          '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
        fontSize: '8px',
        width: '75%',
        margin: 'auto',
        display: 'block'
      }}
      data={data}
      radius={PieChart.defaultProps.radius - 6}
      lineWidth={lineWidth}
      segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
      animate
      label={({ dataEntry, dataIndex }) => dataIndex === selected ? Math.round(dataEntry.percentage) + '%' : null}
      labelPosition={100 - lineWidth / 2}
      labelStyle={{
        fill: '#fff',
        opacity: 0.75,
        pointerEvents: 'none',
      }}
      segmentsShift={(index) => (index === selected ? 6 : 0)}
      onClick={(_, index) => {
        setSelected(index === selected ? undefined : index);
      }}
      onMouseOver={(_, index) => {
        setHovered(index);
      }}
      onMouseOut={() => {
        setHovered(undefined);
      }}
    />
    <div className='pieChartResume' hidden={!props.data}>
      <h1 className='title-layout-header-page'>${parseFloat(props.data?.reduce((ac, val) => (ac + val.value), 0)).toLocaleString('en-US', {minimumFractionDigits: 2})}</h1>
      <span>Total Benefits</span>
    </div>
    </>
  );
}

export default PieChartComponent;